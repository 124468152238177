import { ChevronDown, XCircle } from '@untitled-ui/icons-react'
import React from 'react'
import {
  Button,
  ListBox,
  Select as AriaSelect,
  SelectProps as AriaSelectProps,
  SelectStateContext,
  SelectValue,
  ValidationResult
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { Description, FieldError, Label } from './Field'
import { IconWrapper } from './IconWrapper'
import { DropdownItem, DropdownItemProps } from './ListBox'
import { Popover } from './Popover'
import { composeTailwindRenderProps, groupBaseStyles } from './utils'
import Badge, { BadgeSizes } from '@/src/stories/Badge'
import { t } from '@lingui/macro'

const styles = tv({
  extend: groupBaseStyles,
  base: 'gap-2 justify-between px-4 py-2'
})

export interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, 'children'> {
  label?: string
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
  items?: Iterable<T>
  children: React.ReactNode | ((item: T) => React.ReactNode)
  iconLeading?: React.ReactNode
  iconLeadingIsPlaceholder?: boolean
  hideDescription?: boolean
  onResetSelection?: () => void
  enableClear?: boolean
  openerType?: 'badge' | 'formField'
}

function SelectClearButton({
  openerType
}: {
  openerType?: 'badge' | 'formField'
}) {
  const state = React.useContext(SelectStateContext)
  if (!state.selectedKey) {
    return null
  }

  return (
    <Button
      slot={null}
      className={
        openerType === 'formField'
          ? 'absolute h-full right-10 top-0 outline-0'
          : 'top-0 outline-0'
      }
      onPress={() => {
        state?.setSelectedKey(null)
      }}>
      <IconWrapper width={18} Icon={XCircle} className={'text-slate-500'} />
    </Button>
  )
}

function BadgeSelectedValue({ defaultValue }: { defaultValue: string }) {
  const state = React.useContext(SelectStateContext)
  return <>{state?.selectedKey || defaultValue}</>
}

interface LeadingAdornmentProps {
  adornment: React.ReactNode
  isPlaceholder?: boolean
}

function LeadingAdornment({ adornment, isPlaceholder }: LeadingAdornmentProps) {
  const state = React.useContext(SelectStateContext)
  return state?.selectedKey && isPlaceholder ? null : <>{adornment}</>
}

function Chevron({
  enableClear,
  openerType
}: {
  enableClear?: boolean
  openerType?: 'badge' | 'formField'
}) {
  let state = React.useContext(SelectStateContext)

  return (
    <ChevronDown
      aria-hidden
      className={`transition-transform group-open:rotate-180 w-4 h-4 text-neutral-500 group-disabled:text-neutral-200 focus:outline-0 m-0 p-0 shrink-0 ${
        openerType === 'formField' &&
        enableClear &&
        state?.selectedKey &&
        ' ml-6'
      } ${openerType === 'badge' && 'w-5 h-5 text-neutral-700'}`}
    />
  )
}

function Select<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  iconLeading,
  onResetSelection,
  enableClear,
  iconLeadingIsPlaceholder,
  hideDescription,
  openerType = 'formField',
  ...props
}: SelectProps<T>) {
  return (
    <AriaSelect
      {...props}
      aria-label={label ?? description ?? props.placeholder ?? 'Select'}
      className={composeTailwindRenderProps(
        props.className,
        'group min-w-0 flex flex-col gap-1 relative text-left'
      )}>
      {label && <Label className={'capitalize-first-letter'}>{label}</Label>}
      <div className={'relative flex flex-col'}>
        <Button
          className={
            openerType === 'formField'
              ? styles
              : 'flex flex-row gap-2 w-fit justify-center items-center'
          }>
          <div className={'flex items-center gap-3 grow truncate'}>
            {openerType === 'formField' && (
              <>
                <LeadingAdornment
                  adornment={iconLeading}
                  isPlaceholder={iconLeadingIsPlaceholder}
                />
                <SelectValue
                  className={`block truncate ... [&_span]:block items-center text-sm focus:outline-0 [&_.label-block]:flex-row [&_.label-block]:gap-2 [&_.label-block]:items-center ${
                    hideDescription && '[&_.label-description]:hidden'
                  }`}
                />
              </>
            )}
            {openerType === 'badge' && (
              <Badge size={BadgeSizes.l} color={'neutral'}>
                <div
                  className={'flex flex-row items-center justify-center gap-2'}>
                  <BadgeSelectedValue
                    defaultValue={props.placeholder || t`Select`}
                  />
                  {enableClear && <SelectClearButton openerType={openerType} />}
                </div>
              </Badge>
            )}
          </div>
          <Chevron enableClear={enableClear} openerType={openerType} />
        </Button>
        {enableClear && openerType === 'formField' && (
          <SelectClearButton openerType={openerType} />
        )}
      </div>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <Popover className='min-w-[--trigger-width] scrollbar-hide'>
        <ListBox
          items={items}
          className='w-full outline-none max-h-[inherit] overflow-auto [clip-path:inset(0_0_0_0_round_.75rem)]'>
          {children}
        </ListBox>
      </Popover>
    </AriaSelect>
  )
}

export function SelectItem(props: DropdownItemProps) {
  return <DropdownItem {...props} />
}

export default Select
