import React from 'react'
import {
  Checkbox as AriaCheckbox,
  CheckboxProps as RCACheckboxProps,
  composeRenderProps,
  LabelProps as AriaLabelProps
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import {
  SelectableLabel,
  SelectableSupportiveText
} from '@/src/stories/Selectable'
import { focusRing } from '@/src/stories/utils'

const Check = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 10 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}>
    <path
      d='M9 1L3.5 6.5L1 4'
      stroke='white'
      strokeWidth='1.6666'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const Minus = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 10 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}>
    <path
      d='M1.5 1H8.5'
      stroke='white'
      strokeWidth='1.66666'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const Cross = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}>
    <path
      d='M1 1L9 9M9 1L1 9'
      stroke='white'
      strokeWidth='1.66666'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const checkboxStyles = tv({
  base: 'flex rounded group text-sm transition',
  variants: {
    align: {
      top: 'items-start',
      middle: 'items-center'
    },
    isDisabled: {
      false: '',
      true: ''
    },
    isSelected: {
      false: '',
      true: ''
    },
    isInvalid: {
      false: '',
      true: ''
    }
  }
})

const boxStyles = tv({
  extend: focusRing,
  base: 'p-1 flex-shrink-0 flex items-center justify-center transition cursor-pointer',
  variants: {
    size: {
      s: 'w-4 h-4 rounded',
      m: 'w-5 h-5 rounded-md'
    },
    isSelected: {
      false: '',
      true: ''
    },
    isRoundedFull: {
      false: '',
      true: 'rounded-full'
    },
    isDisabled: {
      false: '',
      true: 'cursor-default'
    },
    isInvalid: {
      false: '',
      true: ''
    }
  },
  compoundVariants: [
    {
      isSelected: true,
      isDisabled: true,
      isInvalid: false,
      className: 'bg-neutral-300 text-neutral-50'
    },
    {
      isSelected: false,
      isDisabled: true,
      isInvalid: false,
      className: 'bg-neutral-100'
    },
    {
      isSelected: false,
      isDisabled: false,
      isInvalid: false,
      className: 'hover:bg-neutral-50'
    },
    {
      isSelected: true,
      isDisabled: false,
      isInvalid: true,
      className: 'bg-rose-500 text-neutral-0'
    },
    {
      isSelected: true,
      isDisabled: false,
      isInvalid: false,
      className: 'bg-highlight-600 text-neutral-0'
    }
  ]
})

const iconStyles = 'w-6 h-6'

export interface CheckboxProps extends RCACheckboxProps {
  size?: 's' | 'm'
  isRoundedFull?: boolean
  align?: 'top' | 'middle'
}

function Checkbox(
  props: CheckboxProps & {
    size?: 's' | 'm'
    isRoundedFull?: boolean
    align?: 'top' | 'middle'
  }
) {
  return (
    <AriaCheckbox
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        checkboxStyles({ ...renderProps, className, align: props.align })
      )}>
      {({ isSelected, isIndeterminate, isInvalid, ...renderProps }) => (
        <>
          <div
            className={boxStyles({
              isSelected: isSelected || isIndeterminate,
              isInvalid,
              isRoundedFull: props.isRoundedFull,
              size: props.size ?? 'm',
              ...renderProps
            })}>
            {isIndeterminate ? (
              <Minus aria-hidden className={iconStyles} />
            ) : isInvalid && isSelected ? (
              <Cross aria-hidden className={iconStyles} />
            ) : isSelected ? (
              <Check aria-hidden className={iconStyles} />
            ) : null}
          </div>

          {!!props.children && (
            <div className={`flex flex-col gap-1 ml-2`}>
              {typeof props.children === 'function'
                ? props.children({
                    ...renderProps,
                    isSelected,
                    isIndeterminate,
                    isInvalid
                  })
                : props.children}
            </div>
          )}
        </>
      )}
    </AriaCheckbox>
  )
}

function CheckboxLabel(props: AriaLabelProps & { isDisabled?: boolean }) {
  return <SelectableLabel {...props} />
}

function CheckboxSupportiveText(
  props: AriaLabelProps & { isDisabled?: boolean }
) {
  return <SelectableSupportiveText {...props} />
}

Checkbox.Label = CheckboxLabel
Checkbox.SupportiveText = CheckboxSupportiveText

export default Checkbox
