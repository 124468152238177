import { Label, LabelProps as AriaLabelProps } from 'react-aria-components'
import React from 'react'
import { tv } from 'tailwind-variants'

const selectableLabelStyles = tv({
  base: "text-base font-normal leading-tight font-['Inter']",
  variants: {
    isDisabled: {
      false: 'text-neutral-700',
      true: 'text-neutral-500'
    },
    isSupportive: {
      false: '',
      true: "text-xs font-medium font-['Plus Jakarta Sans'] leading-tight"
    }
  },
  compoundVariants: [
    {
      isSupportive: true,
      isDisabled: false,
      className: 'text-neutral-600'
    }
  ]
})

function SelectableLabel(props: AriaLabelProps & { isDisabled?: boolean }) {
  return (
    <Label
      {...props}
      className={selectableLabelStyles({
        isDisabled: props.isDisabled
      })}>
      {props.children}
    </Label>
  )
}

function SelectableSupportiveText(
  props: AriaLabelProps & { isDisabled?: boolean }
) {
  return (
    <Label
      {...props}
      className={selectableLabelStyles({
        isDisabled: props.isDisabled,
        isSupportive: true
      })}>
      {props.children}
    </Label>
  )
}

export { SelectableLabel, SelectableSupportiveText }
