import React, { ReactNode, useState } from 'react'
import {
  Button,
  Group,
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  TooltipTrigger,
  ValidationResult
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { Description, FieldError, Input, Label } from './Field'
import Tooltip from './Tooltip'
import {
  composeTailwindRenderProps,
  groupBaseStyles,
  inputBaseStyles
} from './utils'
import { AlertCircle, Eye, EyeOff, HelpCircle } from '@untitled-ui/icons-react'
import { IconWrapper } from './IconWrapper'

const inputStyles = tv({
  extend: inputBaseStyles,
  base: 'h-10'
})

export interface TextFieldProps extends AriaTextFieldProps {
  label?: string
  description?: string
  placeholder?: string
  isRequired?: boolean
  counter?: boolean
  tooltip?: string
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
  tooltipSupportiveText?: string
  type?: 'text' | 'email' | 'password'
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
  maxLength?: number
  LeadingAdornment?: ReactNode
  TrailingAdornment?: ReactNode
  errorMessage?: string | ((validation: ValidationResult) => string)
}

export function TextField({
  label,
  description,
  placeholder,
  errorMessage,
  isDisabled,
  counter,
  maxLength,
  LeadingAdornment,
  TrailingAdornment,
  tooltip,
  tooltipPlacement,
  tooltipSupportiveText,
  type,
  inputMode,
  ...props
}: TextFieldProps) {
  const [characterCount, setCharacterCount] = useState(0)
  const [isHidden, setIsHidden] = useState(true)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value
    setCharacterCount(inputText.length)
  }
  return (
    <AriaTextField
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'flex flex-col gap-1'
      )}>
      {(label || tooltip) && (
        <div className='flex relative justify-between'>
          {label && (
            <Label className={'capitalize-first-letter'} disabled={isDisabled}>
              {label}
            </Label>
          )}
          {tooltip && (
            <TooltipTrigger delay={0}>
              <Button>
                <IconWrapper
                  Icon={HelpCircle}
                  width={16}
                  height={16}
                  color={'#94A3B8'}
                />
              </Button>
              <Tooltip hasArrow placement={tooltipPlacement}>
                <Tooltip.Title>{tooltip}</Tooltip.Title>
                {tooltipSupportiveText && (
                  <Tooltip.SupportiveText>
                    {tooltipSupportiveText}
                  </Tooltip.SupportiveText>
                )}
              </Tooltip>
            </TooltipTrigger>
          )}
        </div>
      )}

      <Group className={groupBaseStyles()} isInvalid={props.isInvalid}>
        {LeadingAdornment && (
          <Button className='items-center focus:outline-none'>
            {LeadingAdornment}
          </Button>
        )}
        <Input
          className={inputStyles()}
          placeholder={placeholder}
          disabled={isDisabled}
          isInvalid={props.isInvalid}
          maxLength={maxLength}
          onChange={handleInputChange}
          type={type === 'password' && isHidden === false ? 'text' : type}
          inputMode={inputMode}
        />
        {type === 'password' && (
          <Button
            className='items-center focus:outline-none mr-4'
            onPress={() => setIsHidden(!isHidden)}>
            <IconWrapper
              Icon={isHidden ? Eye : EyeOff}
              width={16}
              height={16}
              className={'text-slate-500'}
            />
          </Button>
        )}
        {TrailingAdornment && (
          <Button className='items-center focus:outline-none'>
            {TrailingAdornment}
          </Button>
        )}
        {props.isInvalid && (
          <div className='flex items-center content-center pr-4 pointer-events-none'>
            <IconWrapper
              Icon={AlertCircle}
              width={16}
              height={16}
              color={'#EF4444'}
            />
          </div>
        )}
      </Group>
      {(description || counter) && (
        <div className='flex relative justify-between'>
          {description && (
            <Description disabled={isDisabled}>{description}</Description>
          )}
          {counter && maxLength && (
            <Description disabled={isDisabled}>
              {characterCount}/{maxLength}
            </Description>
          )}
        </div>
      )}
      <FieldError className={'text-left'}>{errorMessage}</FieldError>
    </AriaTextField>
  )
}
