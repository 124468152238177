import React from 'react'
import {
  FieldError as RACFieldError,
  FieldErrorProps,
  Input as RACInput,
  InputProps,
  Label as RACLabel,
  LabelProps,
  Text,
  TextProps
} from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { composeTailwindRenderProps } from './utils'

interface CustomLabelProps extends LabelProps {
  disabled?: boolean
}

export function Label(props: CustomLabelProps) {
  return (
    <RACLabel
      {...props}
      className={twMerge(
        `text-body-medium ${
          props.disabled ? 'text-neutral-400' : 'text-neutral-700'
        } cursor-default w-fit`,
        props.className
      )}
    />
  )
}

interface CustomTextProps extends TextProps {
  disabled?: boolean
}

export function Description(props: CustomTextProps) {
  return (
    <Text
      {...props}
      slot='description'
      className={twMerge(
        props.className,
        `text-left text-xs ${
          props.disabled ? 'text-neutral-400' : 'text-neutral-700'
        } `
      )}
    />
  )
}

export function FieldError(props: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'text-xs text-critical-600 flex'
      )}
    />
  )
}

interface CustomInputProps extends InputProps {
  isInvalid?: boolean
}

export function Input(props: CustomInputProps) {
  return (
    <RACInput
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        'aria-input px-4 py-2.5 flex-1 min-w-0 bg-neutral-50 text-body border-0 outline-0'
      )}
    />
  )
}
