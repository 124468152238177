import { Check } from '@untitled-ui/icons-react'
import React from 'react'
import {
  Collection,
  composeRenderProps,
  Header,
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  ListBoxItemProps,
  ListBoxProps as AriaListBoxProps,
  Section,
  SectionProps,
  Text
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { composeTailwindRenderProps, listItemBaseStyles } from './utils'

interface ListBoxProps<T>
  extends Omit<AriaListBoxProps<T>, 'layout' | 'orientation'> {}

export function ListBox<T extends object>({
  children,
  ...props
}: ListBoxProps<T>) {
  return (
    <AriaListBox {...props} className={props.className}>
      {children}
    </AriaListBox>
  )
}

export function ListBoxItem(props: ListBoxItemProps) {
  let textValue =
    props.textValue ??
    (typeof props.children === 'string' ? props.children : undefined)
  return (
    <AriaListBoxItem
      {...props}
      textValue={textValue}
      className={listItemBaseStyles}>
      {composeRenderProps(props.children, children => (
        <>
          {children}
          <div className='absolute left-4 right-4 bottom-0 h-px bg-white/20 forced-colors:bg-[HighlightText] hidden [.group[data-selected]:has(+[data-selected])_&]:block' />
        </>
      ))}
    </AriaListBoxItem>
  )
}

export const dropdownItemStyles = tv({
  base: 'group w-full flex cursor-pointer select-none py-2.5 px-3.5 outline-0 justify-between',
  variants: {
    isDisabled: {
      false: 'text-neutral-900',
      true: 'text-neutral-300 cursor-default'
    },
    isFocused: {
      true: 'bg-neutral-50'
    }
  }
})

export interface DropdownItemProps extends ListBoxItemProps {
  leadingAdornment?: React.ReactNode
  label: string
  description?: string
  trailingAdornment?: React.ReactNode
}

export function DropdownItem({
  leadingAdornment,
  trailingAdornment,
  label,
  description,
  ...props
}: DropdownItemProps) {
  let textValue = props.textValue ?? label
  return (
    <AriaListBoxItem
      {...props}
      textValue={textValue}
      className={composeTailwindRenderProps(
        dropdownItemStyles,
        'text-body card'
      )}>
      {composeRenderProps(props.children, () => (
        <div className={'flex group flex-row w-full justify-between gap-3'}>
          {leadingAdornment && (
            <div className='flex shrink-0 justify-center items-center'>
              {leadingAdornment}
            </div>
          )}
          <div
            className={
              'flex flex-col gap-0.5 w-full label-block items-start justify-start grow'
            }>
            {label && (
              <Text
                slot='label'
                className="block text-base font-['Inter'] leading-tight label truncate ...">
                {label}
              </Text>
            )}
            {description && (
              <Text
                slot='description'
                className="block text-xs font-medium font-['Plus Jakarta Sans'] leading-tight label-description truncate ...">
                {description}
              </Text>
            )}
          </div>
          {trailingAdornment && (
            <div className='flex shrink-0 pl-4'>{trailingAdornment}</div>
          )}
          <div className='flex shrink-0 justify-center items-center'>
            <Check
              width={18}
              className='text-highlight-600 opacity-0 group-selected:opacity-100'
            />
          </div>
        </div>
      ))}
    </AriaListBoxItem>
  )
}

export interface DropdownSectionProps<T> extends SectionProps<T> {
  title?: string
}

export function DropdownSection<T extends object>(
  props: DropdownSectionProps<T>
) {
  return (
    <Section className="first:-mt-[5px] after:content-[''] after:block after:h-[5px]">
      <Header className='text-sm font-semibold text-neutral-500 px-4 py-1 truncate sticky -top-[5px] -mt-px -mx-1 z-10 bg-neutral-100/60 backdrop-blur-md supports-[-moz-appearance:none]:bg-neutral-100 border-y [&+*]:mt-1'>
        {props.title}
      </Header>
      <Collection items={props.items}>{props.children}</Collection>
    </Section>
  )
}
